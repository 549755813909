
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import session from "@/vuex/session";

  @Component({
    components: {},
  })
  export default class Login extends Mixins(utilMixins) {
    async mounted() {
      try {
        const app_token = util.getLocalStorage("app_token");
        const app_lang = util.getLocalStorage("app_lang");
        if (app_token == null) {
          console.log("wrong app login in try");
          util.gotoIllegal(9);
        } else {
          await session.app_login({ token: app_token, toshift: true });
          util.changeLanguage(app_lang ?? "ja");
        }
      } catch (e) {
        console.log("wrong app login in catch");
        util.gotoIllegal(10);
      }
    }
  }
